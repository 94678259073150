<template>
  <b-row>
    <b-col sm="12">
      <b-card>
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Email Marketing - Reportes</h4>
            <div class="small text-muted">Métricas en tiempo real de las campañas en Email Marketing</div>
          </b-col>
        </b-row>
      </b-card>

      <b-card class="email-marketing-reports-card-filter mb-1">        
        <b-row>
          <b-col md="8" class="mt-1">          
            <span v-if="!arr.labelTemporal">
              <strong>Cuenta Monitoreada:</strong> {{this.arr.label}}            
            </span>
            <span v-else class="email-marketing-reports-card-account-temporary">
              <strong>Cuenta Temporal:</strong> {{this.arr.labelTemporal}}
            </span>
            <b-icon icon="blank" />
            <b-icon icon="blank" />
            <b-link size="sm" 
                    class="email-marketing-reports-card-change-account" 
                    @click="openChangeAccount()">
              <b-icon icon="arrow-left-right" />
              Cambiar Cuenta
            </b-link>            
          </b-col>
          <b-col md="4">
            <b-row class="justify-content-end">
              <b-col md="4" class="pr-1">
                <b-form-input type="date" size="sm" v-model="filter.dateStart" placeholder="Fecha Desde"></b-form-input>                
              </b-col>
              <b-col md="4" class="pr-1">
                <b-form-input type="date" size="sm" v-model="filter.dateEnd" placeholder="Fecha Hasta"></b-form-input>                
              </b-col> 
              <b-col md="2" class="pl-0">
                <b-button @click="getReport()" size="sm" class="w-100" variant="dark">
                  Filtrar
                </b-button>       
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>

      <b-card-group deck>
        <b-card class="email-marketing-reports-widget-card-summary">          
          <b-card-text>            
            <strong>{{Intl.NumberFormat('es-AR').format(this.arr.metrics.campaign)}}</strong>                        
            <p>Campañas</p>
          </b-card-text>
        </b-card>        
        <b-card class="email-marketing-reports-widget-card-summary">          
          <b-card-text>            
            <strong>{{Intl.NumberFormat('es-AR').format(this.arr.metrics.sent)}}</strong>                        
            <p>Enviados</p>
          </b-card-text>
        </b-card>
        <b-card class="email-marketing-reports-widget-card-summary">          
          <b-card-text>
            <strong>{{Intl.NumberFormat('es-AR').format(this.arr.metrics.read)}}</strong>
            <p>Leidos</p>
          </b-card-text>
        </b-card>               
        <b-card class="email-marketing-reports-widget-card-summary">          
          <b-card-text>
            <strong>{{Intl.NumberFormat('es-AR').format(this.arr.metrics.clicks)}}</strong>
            <p>Clicks</p>
          </b-card-text>
        </b-card>                 
        <b-card class="email-marketing-reports-widget-card-summary">          
          <b-card-text>            
            <strong>{{Intl.NumberFormat('es-AR').format(this.arr.metrics.unsubscribed)}}</strong>            
            <p>Desuscripciones</p>
          </b-card-text>
        </b-card>                           
      </b-card-group>

      <b-card>
        <b-row>
          <b-col>
            <b-table class="mb-0"
                    responsive="sm"
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="false"
                    :items="table.items"
                    :fields="table.fields"
                    :current-page="table.currentPage"
                    :per-page="table.perPage"                   
                    v-if="table.items.length">                       
                                
                <template v-slot:cell(SendStartDateTime)="data">
                  <b>{{moment(data.item.SendStartDateTime).format('DD MMMM YYYY')}}</b>
                </template>

                <template v-slot:cell(Subject)="data">
                  <b-link :id="'popover-button-open' + data.item.CampaignID">{{data.item.Subject}}</b-link>
                  <b-icon icon="blank" />
                  
                  <b-popover triggers="hover focus" :target="'popover-button-open' + data.item.CampaignID" title="Vista Previa">
                    <b-img :src="'https://app.envialosimple.com/'+data.item.thumbnailURL" target="_blank"></b-img>
                  </b-popover>
                </template>

                <template v-slot:cell(Sent)="data">
                  <strong>{{Intl.NumberFormat('es-AR').format(data.item.Sent)}}</strong>                  
                </template>

                <template v-slot:cell(ReadAmount)="data">
                  <strong>{{Intl.NumberFormat('es-AR').format(data.item.ReadAmount)}}</strong>                  
                </template>                

                <template v-slot:cell(ClickAmount)="data">
                  <strong>{{Intl.NumberFormat('es-AR').format(data.item.ClickAmount)}}</strong>                  
                </template>                

                <template v-slot:cell(TotalUnsubscriptions)="data">
                  <strong>{{Intl.NumberFormat('es-AR').format(data.item.TotalUnsubscriptions)}}</strong>                  
                </template>                

                <template v-slot:cell(spamPoints)="data">
                  <strong>{{parseFloat(data.item.spamPoints).toFixed(2)}} %</strong>                  
                </template>                        

            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
        </b-row>
      </b-card>

      <b-card>
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>
          <b-col>
            <nav>
              <b-pagination class="pull-right mb-0"
                            size="sm"
                            pills=""
                            :total-rows="getRowCount(table.items)"
                            :per-page="table.perPage"
                            v-model="table.currentPage" />
            </nav>
          </b-col>           
        </b-row>
      </b-card>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              size="sm"
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-row>
          <b-col md="12">                
            <b-form-group label="Clave Privada">
              <b-form-input type="text"
                            size="sm"
                            v-model="filter.key"                            
                            placeholder=""
                            autocomplete="off">
              </b-form-input>              
            </b-form-group>      
          </b-col>       
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" size="sm" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" size="sm" @click="changeAccount()">Consultar</b-button>          
        </div>
      </b-modal>

    </b-col>
  </b-row>
</template>

<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'    
  import moment from 'moment'  

  export default {  
    data: () => {
      return {      
        access: {
          module_id: Modules.EMAIL_MARKETING,
          profile_id: Profiles.PERSONAL,
          view_reference: 'reports',
          elements: {}
        },
        table : {
          items: [],
          fields: [
            {key: 'SendStartDateTime', label: 'Fecha', sortable: true},            
            {key: 'Subject', label: 'Asunto'},                        
            {key: 'Sent', label: 'Enviados', class: 'text-center'},       
            {key: 'ReadAmount', label: 'Leidos', class: 'text-center'},       
            {key: 'ClickAmount', label: 'Clicks', class: 'text-center'},       
            {key: 'TotalUnsubscriptions', label: 'Desuscripciones', class: 'text-center'},       
            {key: 'spamPoints', label: 'Puntuación Spam', class: 'text-center'},       
          ],          
          currentPage: 1,
          perPage: 50,
        },         
        arr: {
          data: [],
          label: '',          
          labelTemporal: '',          
          metrics: {
            campaign: 0, 
            sent: 0,            
            read: 0,
            clicks: 0,
            unsubscribed: 0,            
          }
        },
        filter: {
          id: 1,
          dateStart: moment().startOf('month').format('YYYY-MM-DD'),
          dateEnd:  moment().format('YYYY-MM-DD'),
          key: '',
        },
        modal: {
          form: {
            active: false,
            title: ''
          },
        },                                       
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {      
      this.showEmailMarketing()
      this.getReport()
    },
    methods: {        
      getRowCount (items) {
        return items.length
      },      
      showEmailMarketing() {        
        let loader = this.$loading.show();        
        var result = serviceAPI.mostrarEmailMarketing(this.filter.id)

        result.then((response) => {
          loader.hide()
          var data = response.data
          this.arr.label = data.name                                         
        })
        .catch(error => {          
          loader.hide()            
          this.$awn.alert(Error.showError(error))
        });        
      },               
      getReport() {        
        let loader = this.$loading.show();        
        var result = serviceAPI.obtenerReportGeneralSummaryEmailMarketing(this.filter)

        result.then((response) => {
          loader.hide()
          var data = response.data
          this.table.items = data.data 
          this.arr.data = data.data            
          this.arr.labelTemporal= data.label                         
      
          this.calculate()     
        })
        .catch(error => {             
          loader.hide()            
          this.$awn.alert(Error.showError(error))
        });        
      },             
      calculate () {        
        this.arr.metrics.campaign = 0
        this.arr.metrics.sent = 0
        this.arr.metrics.read = 0
        this.arr.metrics.clicks = 0
        this.arr.metrics.unsubscribed = 0                  

        if(this.arr.data) {                    
          for (const prop in this.arr.data) {
            var element = this.arr.data[prop]
            
            this.arr.metrics.campaign = this.arr.metrics.campaign + 1
            this.arr.metrics.sent = this.arr.metrics.sent + parseInt(element.Sent)
            this.arr.metrics.read = this.arr.metrics.read + parseInt(element.ReadAmount)
            this.arr.metrics.clicks = this.arr.metrics.clicks + parseInt(element.ClickAmount)
            this.arr.metrics.unsubscribed = this.arr.metrics.unsubscribed + parseInt(element.TotalUnsubscriptions)            
          }                             
        }
      }, 
      openChangeAccount() {
        this.modal.form.title = "Cambiar Cuenta"
        this.modal.form.active = true
      },
      changeAccount() {
        this.getReport()
        this.modal.form.active = false
      }
    }    
  }
</script>

<style>
  .email-marketing-reports-widget-card-chart .card-header {      
    padding: 5px;
  }
  .email-marketing-reports-widget-card-chart .card-body {
    padding: 5px;
  }
  .email-marketing-reports-widget-card-summary {
    height: 65px;    
    margin-bottom: 5px !important;
  }
  .email-marketing-reports-widget-card-summary .card-body {
    padding: 5px;
  }
  .email-marketing-reports-widget-card-summary .card-text {
    text-align: center;
  } 
  .email-marketing-reports-widget-card-summary strong {
    font-size: 20px;
  }  
  .email-marketing-reports-widget-card-summary p {
    margin-bottom: 0px;
  }      
  .email-marketing-reports-widget-card-char-alert {
    margin-bottom: 0px;
  }
  .email-marketing-reports-card-filter .card-body {
    padding-left: 15px;        
    padding-top: 10px;        
    padding-bottom: 10px;        
  }
  .email-marketing-reports-card-change-account {
    font-size: 12px;
  }
  .email-marketing-reports-card-account-temporary {
    color:red;
    font-style: italic;
  }
</style>